export const THRESHOLD_MOBILE = 1024;

import { useWindowWidth } from '@react-hook/window-size';

const useIsMobile = (): boolean => {
  const width = useWindowWidth();
  return width < THRESHOLD_MOBILE;
};

export default useIsMobile;
