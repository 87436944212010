import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ICompanyClaims } from 'typings/generated/contentful';

interface Props {
  vertical?: boolean;
  claim: ICompanyClaims;
}

function CompanyClaim({ claim, vertical }: Props): ReactElement {
  return (
    <div
      className={cx('flex flex-col max-w-sm space-y-4', {
        'lg:flex-row items-center lg:space-x-8 lg:space-x-0 lg:space-y-0': !vertical,
      })}
    >
      <div>
        <GatsbyImage
          className="w-24 h-24"
          fluid={getFluidImage(claim.fields.icon, { fm: null })}
        />
      </div>
      <div
        className={cx('flex flex-col space-y-2', {
          'text-center': !vertical,
        })}
      >
        <h3
          className={cx('text-lg font-medium', {
            'text-center': !vertical,
          })}
        >
          {claim.fields.claim}
        </h3>
        <p>{claim.fields.description}</p>
      </div>
    </div>
  );
}

export default CompanyClaim;
