import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { ISectionProductBenefits } from 'typings/generated/contentful';

import CTAButton from '../widgets/cta-button';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionProductBenefits;
}

function ProductBenefits({ section }: Props): ReactElement {
  return (
    <section
      className="relative bg-bg-light-green-2"
      id={section.fields.anchor}
    >
      <div className="absolute top-0 bottom-0 left-0 hidden w-1/3 md:block bg-bg-light-green-2" />
      <SectionContent className="relative">
        <div className="grid w-full grid-cols-1 lg:mt-16 md:mt-24 gap-y-16 md:gap-x-8 md:grid-cols-2">
          <div className="w-full h-full max-h-24">
            <GatsbyImage
              className="w-full h-full contain"
              fluid={getFluidImage(section.fields.sectionImage)}
            />
          </div>
          <div className="relative flex flex-col lg:-mt-12">
            <SectionTitle
              classNames={{
                preHeader: 'text-left',
                mainHeader: 'text-left',
                divider: 'hidden',
              }}
              sectionData={section.fields.sectionData}
            />
            <div className="mt-8">
              {section.fields.productClaims?.map((claim) => (
                <div className="flex items-center py-2" key={claim.sys.id}>
                  <GatsbyImage
                    className="flex-shrink-0 block w-5 h-5 mr-4"
                    fluid={getFluidImage(claim.fields.icon)}
                  />
                  <h3 className="md:text-xl">{claim.fields.claim}</h3>
                </div>
              ))}
            </div>
            <CTAButton
              button={section.fields.ctaButton}
              centered={false}
              className="mt-16"
            />
          </div>
        </div>
      </SectionContent>
    </section>
  );
}

export default ProductBenefits;
