import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import { getFluidImage } from 'src/util/get-fluid-image';
import { IHeroSection } from 'typings/generated/contentful';

interface Props {
  section: IHeroSection;
  isMobile: boolean;
}

function Hero({ section, isMobile }: Props): ReactElement {
  return (
    <section>
      <div className="relative flex flex-col justify-center bg-bg-dark-green">
        {/* background image */}
        <div className="absolute top-0 bottom-0 left-0 right-0 h-full pointer-events-none">
          <GatsbyImage
            className="object-contain object-right h-full img-right-75"
            fluid={getFluidImage(section.fields.backgroundImage, {
              w: isMobile ? 768 : null,
            })}
          />
        </div>
        {/* dimming */}
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50" />

        {/* text content */}
        <div className="relative flex justify-center px-8 py-24 text-white lg:py-64">
          <div className="w-screen max-w-screen-xl">
            <div className="flex flex-col items-center justify-center w-full h-full max-w-md mx-auto text-center lg:max-w-none">
              <div className="font-medium md:text-2xl">
                {section.fields.preHeader}
              </div>
              <h1 className="text-2xl font-bold md:text-3xl lg:mt-4 lg:text-5xl lg:w-3/5">
                {section.fields.mainHeader}
              </h1>
              <div className="hidden mt-8 text-xl font-medium break-normal md:text-2xl lg:block">
                <div className="max-w-2xl">{section.fields.subHeader}</div>
              </div>
              <a
                className="hidden px-4 py-2 mt-2 text-lg text-blue-900 bg-white rounded lg:mt-16 lg:text-xl lg:block"
                href={section.fields.ctaButton?.fields.link}
              >
                {section.fields.ctaButton?.fields.copy}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* mobile subheader */}
      <div className="block px-8 py-4 text-center text-white break-normal bg-bg-dark-green md:text-xl lg:hidden lg:text-2xl">
        <div className="max-w-xs m-auto">{section.fields.subHeader}</div>
      </div>

      {/* mobile cta */}
      <div className="absolute bottom-0 left-0 right-0 lg:hidden bg-primary">
        {/* ToDo */}
      </div>
    </section>
  );
}

export default Hero;
