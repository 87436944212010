import { Entry } from 'contentful';
import React, { ReactElement, useCallback, useMemo } from 'react';
import Blog from 'src/components/sections-home/Blog';
import CompanyClaims from 'src/components/sections-home/CompanyClaims';
import Hero from 'src/components/sections-home/Hero';
import ProductBenefits from 'src/components/sections-home/ProductBenefits';
import Testimonials from 'src/components/sections-home/Testimonials';
import Footer from 'src/components/sections/footer';
import Navbar from 'src/components/sections/navbar';
import CTAButton from 'src/components/widgets/cta-button';
import Favicon from 'src/components/widgets/favicon';
import SEO from 'src/components/widgets/seo';
import { useLayout } from 'src/util/useLayout';
import {
  ICta,
  IHeroSection,
  ILandingPage,
  ISectionBlog,
  ISectionCompanyClaims,
  ISectionFooter,
  ISectionHeader,
  ISectionProductBenefits,
  ISectionTestimonials,
  IStaticPages,
} from 'typings/generated/contentful';

interface Props {
  location: Location;
  pageContext: {
    reactContext: {
      staticPages: IStaticPages[];
    };
    landingPage: ILandingPage;
  };
}

export default function LandingPage(props: Props): ReactElement {
  const {
    location,
    pageContext: { landingPage },
  } = props;
  const layout = useLayout();

  const renderSection = useCallback(
    (section: Entry<unknown>, isMobile: boolean) => {
      switch (section.sys.contentType.sys.id) {
        case 'sectionHeader':
          return <Navbar section={section as ISectionHeader} />;
        case 'heroSection':
          return <Hero isMobile={isMobile} section={section as IHeroSection} />;
        case 'sectionCompanyClaims':
          return (
            <CompanyClaims
              color="white"
              section={section as ISectionCompanyClaims}
            />
          );
        case 'sectionTestimonials':
          return <Testimonials section={section as ISectionTestimonials} />;
        case 'sectionProductBenefits':
          return (
            <ProductBenefits section={section as ISectionProductBenefits} />
          );
        case 'sectionBlog':
          return <Blog section={section as ISectionBlog} />;
        case 'sectionFooter':
          return <Footer color="green" section={section as ISectionFooter} />;
        default:
          return null;
      }
    },
    []
  );

  const stickyCTA: ICta | undefined = useMemo(
    () =>
      landingPage.fields.content?.find(
        (content) => content.sys.contentType.sys.id === 'cta'
      ) as ICta | undefined,
    [landingPage.fields.content]
  );

  return (
    <div className="flex flex-col text-primary">
      <Favicon icon={landingPage.fields.favicon} />
      <SEO canonical={location.href} seo={landingPage.fields.seoInformation} />
      <div>
        {landingPage.fields.content?.map((section) =>
          renderSection(section, false)
        )}
      </div>
      {layout !== 'desktop' && (
        <div className="lg:hidden">
          {/* push content above so cta does not hide page content */}
          {stickyCTA && <div className="h-32" />}
          {/* mobile cta */}
          {stickyCTA && (
            <div
              className="fixed bottom-0 left-0 right-0 z-10 px-8 py-4 text-white bg-bg-dark-green"
              data-aos="slide-up"
              data-aos-offset="200"
            >
              <CTAButton button={stickyCTA} smaller />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
