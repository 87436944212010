import React, { ReactElement } from 'react';
import { ISectionTestimonials } from 'typings/generated/contentful';

import SectionContent from '../widgets/section-content';

interface Props {
  section: ISectionTestimonials;
}

export default function Testimonials({ section }: Props): ReactElement {
  return (
    <section id={section.fields.anchor}>
      <SectionContent>
        <div className="grid gap-8 lg:grid-cols-3">
          {section.fields.testimonials?.map((testimonial) => (
            <blockquote className="flex flex-col" key={testimonial.sys.id}>
              <QuoteIcon />
              <p className="mt-8 text-gray-800">
                {testimonial.fields.testimonial}
              </p>
              <div className="mt-4 font-semibold">
                {[
                  testimonial.fields.firstName,
                  testimonial.fields.lastName,
                ].join(' ')}
                , {testimonial.fields.location}
              </div>
            </blockquote>
          ))}
        </div>
      </SectionContent>
    </section>
  );
}

function QuoteIcon() {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 24 18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1793_5144)">
        <path
          d="M0.000156688 18H8.70016V12.445C8.67616 8.539 9.81316 5.604 11.5922 2.765L6.45216 0C3.18816 2.644 -0.0258433 7.86 0.000156688 12.469V18ZM12.4082 18H21.1082V12.445C21.0832 8.539 22.2202 5.604 24.0002 2.765L18.8592 0C15.5962 2.644 12.3832 7.86 12.4082 12.469V18Z"
          fill="#D1D5DB"
        />
      </g>
      <defs>
        <clipPath id="clip0_1793_5144">
          <rect fill="white" height="18" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
}
