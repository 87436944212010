import { useEffect, useState } from 'react';

import useIsMobile from './useIsMobile';

type ILayout = 'desktop' | 'mobile' | null;

export const useLayout = (): ILayout => {
  const isMobile = useIsMobile();
  const [layout, setLayout] = useState<ILayout>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLayout(isMobile ? 'mobile' : 'desktop');
    }
  }, [isMobile]);

  return layout;
};
