import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import GatsbyImage from 'gatsby-image';
import React, { ReactElement } from 'react';
import moment from 'src/lib/moment';
import { getFluidImage } from 'src/util/get-fluid-image';
import { IBlogPost } from 'typings/generated/contentful';

type Props = {
  post: IBlogPost;
};

export default function PostPreview({ post }: Props): ReactElement {
  const href = `/blog/${post.fields.slug}`;

  return (
    <article
      className="flex flex-col items-start justify-between"
      key={post.sys.id}
    >
      <div className="relative w-full">
        <a href={href}>
          <GatsbyImage
            alt={post.fields.heroImage.fields.title}
            className="aspect-video w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
            fluid={getFluidImage(post.fields.heroImage, {
              w: 680,
              fm: 'webp',
              q: 100,
            })}
          />
          <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
        </a>
      </div>
      <div className="max-w-xl">
        <div className="flex items-center mt-8 text-xs gap-x-4">
          <time className="text-gray-500" dateTime={post.sys.createdAt}>
            {moment(post.sys.createdAt).format('ll')}
          </time>
        </div>
        <div className="relative group">
          <h3 className="mt-2 text-lg font-semibold leading-6 text-gray-900 truncate md:mt-4 group-hover:text-gray-600">
            <a
              className="whitespace-pre-wrap line-clamp-2 md:min-h-[48px]"
              href={href}
            >
              {post.fields.title}
            </a>
          </h3>
          <p className="mt-2 text-sm leading-6 text-gray-600 line-clamp-3">
            {documentToPlainTextString(post.fields.content).slice(0, 400)}
          </p>
        </div>
      </div>
    </article>
  );
}
