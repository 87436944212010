import React, { ReactElement } from 'react';
import { ISectionBlog } from 'typings/generated/contentful';

import PostPreview from '../widgets/PostPreview';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  section: ISectionBlog;
}

export default function Blog({ section }: Props): ReactElement {
  return (
    <section id={section.fields.anchor}>
      <SectionContent>
        <SectionTitle
          classNames={{ divider: 'hidden' }}
          sectionData={section.fields.sectionData}
        />
        <div className="grid max-w-2xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {section.fields.posts?.map((post) => (
            <PostPreview key={post.sys.id} post={post} />
          ))}
        </div>
      </SectionContent>
    </section>
  );
}
