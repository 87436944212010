import React, { ReactElement } from 'react';
import { ISectionCompanyClaims } from 'typings/generated/contentful';

import CompanyClaim from '../widgets/company-claim';
import SectionContent from '../widgets/section-content';
import SectionTitle from '../widgets/section-title';

interface Props {
  color?: 'blue' | 'white';
  section: ISectionCompanyClaims;
}

function CompanyClaims({ section, color = 'blue' }: Props): ReactElement {
  return (
    <section
      className={color === 'blue' ? 'bg-bg-light' : undefined}
      id={section.fields.anchor}
    >
      <SectionContent>
        <SectionTitle
          classNames={{ divider: 'hidden' }}
          sectionData={section.fields.sectionData}
        />
        <div className="grid grid-cols-2 py-6 mt-16 gap-y-16 gap-x-4 lg:gap-24 lg:grid-cols-3">
          {section.fields.companyClaims?.map((claim) => (
            <CompanyClaim claim={claim} key={claim.sys.id} vertical />
          ))}
        </div>
      </SectionContent>
    </section>
  );
}

export default CompanyClaims;
